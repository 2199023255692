import React from "react"
import styled from "styled-components"

import devices from "../../helpers/devices"

const StyledBanner = styled.div`
  position: fixed;
  bottom: 0;
  z-index: 99;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;

  background-color: var(--color-primary);
  color: #fff;

  padding: 1rem;

  span {
    margin-bottom: 1rem;
    vertical-align: middle;

    a {
      color: #fff;
      text-decoration: underline;
      text-transform: lowercase;
    }
  }

  div {
    flex-shrink: 0;

    button:not(:last-child) {
      margin-right: 0.5rem;
    }
  }

  @media ${devices.desktop} {
    flex-direction: row;

    span {
      margin: 0 1rem 0 0;
    }
  }
`

interface BannerProps {
  l10n: SConsentSolutionTypes.L10N
  onAcceptAllClick: (event: React.MouseEvent<Element, MouseEvent>) => void
  onRejectAllClick: (event: React.MouseEvent<Element, MouseEvent>) => void
  onCustomizeClick: (event: React.MouseEvent<Element, MouseEvent>) => void
  readMore?: JSX.Element
}

const Banner: React.FunctionComponent<BannerProps> = ({
  l10n,
  onAcceptAllClick,
  onRejectAllClick,
  onCustomizeClick,
  readMore,
}) => (
  <StyledBanner>
    <span>
      {l10n.message} {readMore}
    </span>
    <div>
      <button
        aria-label={l10n.btnAcceptAll}
        className="button has-text-dark is-small is-success"
        onClick={onAcceptAllClick}
      >
        {l10n.btnAcceptAll}
      </button>
      <button
        aria-label={l10n.btnRejectAll}
        className="button is-small"
        onClick={onRejectAllClick}
      >
        {l10n.btnRejectAll}
      </button>
      <button
        aria-label={l10n.btnCustomize}
        className="button is-small"
        onClick={onCustomizeClick}
      >
        {l10n.btnCustomize}
      </button>
    </div>
  </StyledBanner>
)

export default Banner
