import Helmet from "react-helmet"
import React from "react"
import { useStaticQuery, graphql } from "gatsby"

function getTitleFromTemplate(brand: string, title: string): string {
  return `${title} | ${brand}`
}

interface MetaProps {
  description?: string
  image?: {
    height: number
    src: string
    width: number
  }
  meta?: (
    | {
        name: string
        content: any
        property?: undefined
      }
    | {
        property: string
        content: any
        name?: undefined
      }
  )[]
  pathname: string
  title: string
  useTitleTemplate?: boolean
}

const Meta: React.FunctionComponent<MetaProps> = ({
  description,
  image,
  meta,
  pathname,
  title,
  useTitleTemplate,
}) => {
  const {
    site: {
      siteMetadata: { author, siteUrl, title: brand },
    },
  } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            author
            siteUrl
            title
          }
        }
      }
    `
  )

  const canonicalUrl = `${siteUrl}${pathname}`
  const locale = "it"
  const metaDescription =
    description ||
    "Esclusivo Club di pole dance a Milano, corsi di pole dance, exotic dance, mobility/flexibility. Lezioni private o di gruppo (max 6 persone) da 75 minuti."
  const metaImageHeight = image ? image.height : 1200
  const metaImageSrc = image ? image.src : `${siteUrl}/og_image.png`
  const metaImageWidth = image ? image.width : 1200
  const metaTitle = useTitleTemplate
    ? getTitleFromTemplate(brand, title)
    : title

  const defaultMeta = [
    { name: "description", content: metaDescription },
    { name: "twitter:card", content: "summary_large_image" },
    { name: "twitter:creator", content: author },
    { name: "twitter:description", content: metaDescription },
    { name: "twitter:image", content: metaImageSrc },
    { name: "twitter:site", content: canonicalUrl },
    { name: "twitter:title", content: metaTitle },
    { property: "og:description", content: metaDescription },
    { property: "og:image", content: metaImageSrc },
    { property: "og:image:height", content: metaImageHeight },
    { property: "og:image:width", content: metaImageWidth },
    { property: "og:locale", content: locale },
    { property: "og:title", content: metaTitle },
    { property: "og:type", content: "website" },
    { property: "og:url", content: canonicalUrl },
  ]

  return (
    <>
      <Helmet
        defer={false}
        htmlAttributes={{ lang: locale }}
        link={[{ href: canonicalUrl, rel: "canonical" }]}
        meta={meta ? defaultMeta.concat(meta) : defaultMeta}
        title={metaTitle}
      />
      <Helmet>
        <script type="application/ld+json">
          {`{
            "@context": "https://schema.org",
            "@type": "Organization",
            "name": "Pole Club Milano",
            "url": "https://poleclubmilano.com/",
            "logo": "https://poleclubmilano.com/og_image.png",
            "sameAs": [
              "https://www.instagram.com/poleclubmilano/"
            ]
          }`}
        </script>
      </Helmet>
    </>
  )
}

Meta.defaultProps = {
  meta: [],
  useTitleTemplate: true,
}

export default Meta
