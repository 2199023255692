export const cookies: { [key: string]: any } = {
  necessary: {
    scs: {
      cookies: [
        "scs_marketing_cookies_enabled",
        "scs_preferences_cookies_enabled",
        "scs_statistics_cookies_enabled",
        "scs_updated_at",
      ],
      expiresAfter: "1 year",
      mustWipe: false,
      name: "SCS",
      showInPolicy: true,
    },
  },
  statistics: {
    fullstory: {
      cookies: ["fs_uid"],
      expiresAfter: "1 year",
      mustWipe: false,
      name: "Fullstory",
      showInPolicy: false,
    },
    googleAnalytics: {
      cookies: [
        "_dc_gtm_8SNYKQ01T3",
        "_ga",
        "_ga_8SNYKQ01T3",
        "_gid",
        "__utma",
        "__utmb",
        "__utmc",
        "__utmx",
        "__utmxx",
        "__utmz",
      ],
      expiresAfter: "2 years",
      mustWipe: true,
      name: "Google Analytics",
      showInPolicy: true,
    },
  },
  marketing: {},
}

export const listCookiesToWipe = (categoryId: string): string[] =>
  Object.keys(cookies[categoryId]).reduce(
    (acc: string[], serviceId: string) => {
      if (!cookies[categoryId][serviceId].mustWipe) return acc
      return [...acc, ...cookies[categoryId][serviceId].cookies]
    },
    []
  )

export default cookies
