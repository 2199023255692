import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import { EVENT_SHOW_OPTIONS_MODAL } from "../components/scs/constants"

const ButtonLink = styled.button`
  height: auto;
  padding: 0;
  border: none;
  color: #4e4b48;
  cursor: pointer;
  text-decoration: none;
`

const Wrapper = styled.footer`
  h4,
  .title-link {
    margin-bottom: 0.5rem !important;
  }

  h4 {
    font-size: 1.5rem;
    font-weight: 600;
  }

  ul {
    margin: 0 0 1.5rem 0;

    list-style: none;

    li:not(:last-child) {
      margin-bottom: 0.5rem;
    }
  }

  .copyright {
    font-size: 0.75rem;
    text-align: center;
  }
`

const Footer: React.FunctionComponent = () => {
  function openCookieSettings() {
    document.dispatchEvent(new Event(EVENT_SHOW_OPTIONS_MODAL))
  }

  return (
    <Wrapper className="section" id="footer">
      <div className="container">
        <div className="columns">
          <div className="column is-3 has-text-centered">
            <Link className="title-link" title="Home" to="/">
              <h4 className="title">Pole Club Milano</h4>
            </Link>
            <ul>
              <li>P. IVA 11142860961</li>
            </ul>
          </div>
          <div className="column is-3 has-text-centered">
            <h4 className="title">Contatti</h4>
            <ul>
              <li>
                <a
                  href="https://goo.gl/maps/mpo1iszkErcjSFR77"
                  rel="noreferrer"
                  target="_blank"
                >
                  Viale di porta Vercellina 12/A, 20123 Milano
                </a>
              </li>
              <li>
                <a
                  href="mailto:vercellina12@poleclubmilano.com"
                  rel="noreferrer"
                  target="_blank"
                >
                  vercellina12@poleclubmilano.com
                </a>
              </li>
            </ul>
          </div>
          <div className="column is-3 has-text-centered">
            <h4 className="title">Social</h4>
            <ul>
              <li>
                <a
                  href="https://www.instagram.com/poleclubmilano/"
                  rel="noreferrer"
                  target="_blank"
                  title="Instagram"
                >
                  Instagram
                </a>
              </li>
            </ul>
          </div>
          <div className="column is-3 has-text-centered">
            <h4 className="title">Terms &amp; Conditions</h4>
            <ul>
              <li>
                <Link title="Cookie Policy" to="/cookie-policy/">
                  Cookie Policy
                </Link>
                &nbsp;&middot;&nbsp;
                <ButtonLink
                  className="button"
                  onClick={() => openCookieSettings()}
                  title="Settings"
                  type="button"
                >
                  Settings
                </ButtonLink>
              </li>
              <li>
                <Link title="Privacy Policy" to="/privacy-policy/">
                  Privacy Policy
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="columns">
          <div className="column">
            <p className="copyright">© 2020-2024 Pole Club Milano</p>
            <p className="copyright">
              App Store and the Apple logo are trademarks of Apple Inc.
            </p>
            <p className="copyright">
              Google Play and the Google Play logo are trademarks of Google LLC.
            </p>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

export default Footer
