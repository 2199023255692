// https://stackoverflow.com/a/25456134/1439336
export const deepEqual = function (x: any, y: any) {
  if (x === y) {
    return true
  }
  if (
    typeof x === "object" &&
    x != null &&
    typeof y === "object" &&
    y != null
  ) {
    if (Object.keys(x).length != Object.keys(y).length) return false

    for (const prop in x) {
      if (y.hasOwnProperty(prop)) {
        if (!deepEqual(x[prop], y[prop])) return false
      } else return false
    }

    return true
  }
  return false
}
