import { Link } from "gatsby"
import React, { useState } from "react"
import styled from "styled-components"

import Logo from "../assets/Logo.svg"

const Component = styled.nav`
  &.navbar {
    &.is-transparent {
      background-color: transparent;
      background-image: none;
    }

    &.must-blend {
      background-image: none;
    }

    .navbar-brand .logo {
      height: 56px;
      max-height: 56px;
    }

    .navbar-item.is-active {
      color: var(--color-primary);
    }
  }
`

interface NavbarProps {
  className?: string
  isBrandVisible?: boolean
  isFixedTop?: boolean
  isTransparent?: boolean
  mustBlend?: boolean
}

const Navbar: React.FunctionComponent<NavbarProps> = ({
  className,
  isBrandVisible,
  isFixedTop,
  isTransparent,
  mustBlend,
}) => {
  const [isMenuOpen, setMenuOpen] = useState(false)

  function getIsActive(pathname: string): string {
    return typeof window !== "undefined" &&
      typeof window.location !== "undefined" &&
      window.location.pathname.includes(pathname)
      ? "is-active"
      : ""
  }

  function getFullClassName(): string {
    let fullClassName = "navbar"
    if (className) fullClassName = `${fullClassName} ${className}`
    if (isFixedTop) fullClassName = `${fullClassName} is-fixed-top`
    if (isTransparent) fullClassName = `${fullClassName} is-transparent`
    if (mustBlend) fullClassName = `${fullClassName} must-blend`
    return fullClassName
  }

  return (
    <Component
      className={getFullClassName()}
      role="navigation"
      aria-label="main navigation"
    >
      <div className="container">
        <div className="navbar-brand">
          {isBrandVisible ? (
            <Link aria-label="Home" className="navbar-item" to="/">
              <img alt="Pole Club Milano" className="logo" src={Logo} />
            </Link>
          ) : undefined}
          <a
            aria-expanded={isMenuOpen}
            aria-label="menu"
            className={
              "navbar-burger burger " + (isMenuOpen ? "is-active" : "")
            }
            data-target="navbar-menu"
            onClick={() => setMenuOpen(!isMenuOpen)}
            role="button"
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
        </div>
        <div
          id="navbar-menu"
          className={"navbar-menu " + (isMenuOpen ? "is-active" : "")}
        >
          <div className="navbar-end">
            {/* <Link
              className={"navbar-item " + getIsActive("/?#discover")}
              to="/?#discover"
            >
              CORSI
            </Link> */}
            <Link
              className={"navbar-item " + getIsActive("#footer")}
              to="#footer"
            >
              CONTATTI
            </Link>
          </div>
        </div>
      </div>
    </Component>
  )
}

Navbar.defaultProps = {
  isBrandVisible: true,
  isFixedTop: false,
  isTransparent: false,
  mustBlend: false,
}

export default Navbar
