export const VENDOR_PREFIX = "scs"

export const CAT_MARKETING = "marketing"
export const CAT_NECESSARY = "necessary"
export const CAT_PREFERENCES = "preferences"
export const CAT_STATISTICS = "statistics"

export const CATEGORIES = [CAT_PREFERENCES, CAT_STATISTICS, CAT_MARKETING]
export const COOKIE_UPDATED_AT = `${VENDOR_PREFIX}_updated_at`
export const EVENT_SHOW_OPTIONS_MODAL = `${VENDOR_PREFIX}_show_options_modal`
