import React, { useMemo } from "react"
import { Link } from "gatsby"

import Favicon from "./Favicon"
import SCS from "./scs"
import { listCookiesToWipe } from "../helpers/cookies"

declare const dataLayer: {
  push: Function
}

interface LayoutProps {
  children?: any
}

const Layout: React.FunctionComponent<LayoutProps> = ({ children }) => {
  const onConsentChanged = (consent: SCS.Consent) => {
    if (
      consent.marketing.hasBeenRevoked ||
      consent.preferences.hasBeenRevoked ||
      consent.statistics.hasBeenRevoked
    ) {
      window.location.reload()
      return
    }

    if (consent.statistics.hasConsent) {
      if (process.env.NODE_ENV === "production") {
        dataLayer.push({ event: "has_statistics_cookie_consent" })
      }
    }

    if (consent.marketing.hasConsent) {
      if (process.env.NODE_ENV === "production") {
        dataLayer.push({ event: "has_marketing_cookie_consent" })
      }
    }
  }
  const cookies = useMemo(
    () => ({
      marketing: listCookiesToWipe("marketing"),
      statistics: listCookiesToWipe("statistics"),
    }),
    [listCookiesToWipe]
  )

  const l10n = useMemo<SCS.L10N>(
    () => ({
      message:
        "Utilizziamo i cookie per offrirti una migliore esperienza utente, analizzando il traffico e l'utilizzo del nostro sito. Cliccando su Accetta tutti acconsenti all'utilizzo dei cookie in conformità con la nostra",
      btnAcceptAll: "Accetta tutti",
      btnRejectAll: "Rifiuta tutti",
      btnCustomize: "Personalizza",
      modalTitle: "Preferenze sul consenso",
      btnAccept: "Accetta",
      btnCancel: "Annulla",
      marketingDesc:
        "Questi cookie o altri identificatori ci consentono di fornire contenuti di marketing personalizzati basati sul tuo comportamento e di operare, servire e tracciare annunci.",
      marketingName: "Marketing",
      necessaryDesc:
        "Questi cookie o altri identificatori sono richiesti per consentire il funzionamento del nostro servizio e, per tanto, non richiedono il consenso dell'utente.",
      necessaryName: "Strettamente necessari",
      preferencesDesc:
        "Questi cookie o altri identificatori ci consentono di tenere traccia delle tue preferenze relative alle funzionalità del nostro servizio.",
      preferencesName: "Preferenze",
      statisticsDesc:
        "Questi cookie o altri identificatori ci consentono di misurare il traffico ed analizzare il tuo comportamento con l'obiettivo di migliorare il nostro servizio.",
      statisticsName: "Statistica",
    }),
    []
  )

  const readMore = <Link to="/cookie-policy/">Cookie Policy.</Link>

  return (
    <>
      <Favicon />
      {children}
      <SCS
        cookies={cookies}
        l10n={l10n}
        onConsentChanged={onConsentChanged}
        readMore={readMore}
      />
    </>
  )
}

export default Layout
